<template>
    <ValidationObserver ref="obs">
        <v-row>
            <v-col sm="6">
                <TextField v-if="isEditable"
                           :label="incidentFieldsConfiguration.customer.text"
                           disabled
                           :append-icon="ICONS.SINGLE_DOWN"
                           :value="customerName"
                           rules="required"></TextField>
                <Autocomplete :label="incidentFieldsConfiguration.customer.text"
                              v-else
                              :disabled="isEditable"
                              name="customer_id"
                              rules="required"
                              clearable
                              @click:clear="getCustomers(null, null, true)"
                              @load-more-items="getCustomersLazyLoading(true)"
                              :items="listingCustomers"
                              :loading="isCustomersLoading"
                              :search-input.sync="searchCustomers"
                              v-model="customerId"></Autocomplete>
                <TextField v-if="isEditable"
                           :label="incidentFieldsConfiguration.site.text"
                           disabled
                           :append-icon="ICONS.SINGLE_DOWN"
                           :value="siteName"
                           rules="required"></TextField>
                <Autocomplete :label="incidentFieldsConfiguration.site.text"
                              v-else
                              name="site_id"
                              rules="required"
                              :items="sites"
                              clearable
                              @click:clear="getSites(null, null, customerId)"
                              @load-more-items="getSitesLazyLoading(customerId)"
                              :loading="isSitesLoading"
                              :search-input.sync="searchSitesPerCustomer"
                              :disabled="isEditable || !customerId"
                              :success-messages="showSiteSuccessMessage"
                              v-model="siteId"></Autocomplete>
                <TextField v-if="isEditable"
                           :label="incidentFieldsConfiguration.location.text"
                           disabled
                           :append-icon="ICONS.SINGLE_DOWN"
                           :value="locationName"
                           rules="required"></TextField>
                <Autocomplete :label="incidentFieldsConfiguration.location.text"
                              name="location_id"
                              rules="required"
                              v-else
                              clearable
                              @click:clear="getLocations(null, null, siteId)"
                              @load-more-items="getLocationsLazyLoading(siteId)"
                              :items="locations"
                              :loading="isLocationsLoading"
                              :search-input.sync="searchLocationsPerSite"
                              :disabled="isEditable || !siteId"
                              :success-messages="showLocationSuccessMessage"
                              v-model="locationId"></Autocomplete>
                <TextField v-if="isEditable"
                           :label="incidentFieldsConfiguration.type.text"
                           disabled
                           :append-icon="ICONS.SINGLE_DOWN"
                           :value="typeName"
                           rules="required"></TextField>
                <Autocomplete :label="incidentFieldsConfiguration.type.text"
                              v-else
                              name="type"
                              rules="required"
                              clearable
                              :items="types"
                              :loading="isTypesLoading"
                              :search-input.sync="searchTypes"
                              @click:clear="getIncidentsTypes"
                              @load-more-items="getIncidentsTypesLazyLoading"
                              :disabled="isEditable || !locationId"
                              v-model="type"></Autocomplete>
                <Autocomplete :label="incidentFieldsConfiguration.status.text"
                              :items="filterableStatusesList"
                              :checkDefault="true"
                              clearable
                              name="status"
                              rules="required"
                              :disabled="!type"
                              :success-messages="showStatusSuccessMessage"
                              :loading="isStatusLoading"
                              v-model="status"></Autocomplete>
                <Autocomplete :label="incidentFieldsConfiguration.responsibleEmployee.text"
                              :items="employees"
                              clearable
                              :loading="isResponsibleEmployeesLoading"
                              :search-input.sync="searchResponsibleEmployees"
                              @click:clear="getResponsibleEmployees"
                              @load-more-items="getResponsibleEmployeesLazyLoading"
                              name="person_assigned"
                              :disabled="!locationId"
                              :placeholder="ResponsibleEmployeeLabel"
                              v-model="personAssigned">
                </Autocomplete>
            </v-col>
            <v-col sm="6">
                <v-row>
                    <v-col class="py-0">
                        <DatePicker :label="incidentFieldsConfiguration.startDate.text"
                            name="start_date"
                            rules="required"
                            :disabled="isEditable"
                            v-model="startDate"
                        />
                    </v-col>
                    <v-col class="py-0">
                        <TimePicker :label="$t('labels.occurrence_time')"
                            name="start_time"
                            rules="required"
                            :disabled="isEditable"
                            use-seconds
                            v-model="startTime"
                        />
                    </v-col>
                </v-row>
                <v-layout v-if="isEditable" data-test="endDate">
                    <template v-if="requiredEndDate">
                        <DatePicker :label="incidentFieldsConfiguration.endDate.text"
                                    rules="isDateGreaterOrEqual:@start_date|required"
                                    name="end_date"
                                    class-name="mr-2"
                                    :clearable="true"
                                    @input="clearEndTime($event)"
                                    v-model="endDate"></DatePicker>
                        <TimePicker :label="$t('labels.end_time')"
                                    name="end_time"
                                    use-seconds
                                    rules="required"
                                    class-name="ml-2"
                                    :disabled="!endDate"
                                    v-model="endTime"></TimePicker>
                    </template>
                    <!--<template v-else>-->
                    <!--<DatePicker :label="incidentFieldsConfiguration.endDate.text"-->
                    <!--rules="isDateGreaterOrEqual:@start_date"-->
                    <!--name="end_date"-->
                    <!--class-name="mr-2"-->
                    <!--:clearable="true"-->
                    <!--@input="clearEndTime($event)"-->
                    <!--v-model="endDate"></DatePicker>-->
                    <!--<TimePicker :label="$t('labels.end_time')"-->
                    <!--name="end_time"-->
                    <!--use-seconds-->
                    <!--class-name="ml-2"-->
                    <!--:disabled="!endDate"-->
                    <!--v-model="endTime"></TimePicker>-->
                    <!--</template>-->

                </v-layout>
                <TextareaField :label="$t('labels.description')"
                               name="description"
                               :rules="{ max: 350 }"
                               :disabled="isEditable"
                               v-model="description"></TextareaField>
                <FileInput :label="$t('labels.photos')"
                           :form-visibility="incidentFormVisibility"
                           name="files"
                           ref="files"
                           :accept="acceptedFiles"
                           input-id="1"
                           :multiple="true"
                           v-model="files"></FileInput>
                <FileInput :label="$t('labels.documents')"
                           :form-visibility="incidentFormVisibility"
                           name="documents"
                           ref="documents"
                           raw
                           accept="application/pdf"
                           input-id="2"
                           :multiple="true"
                           :icon="ICONS.DOCUMENT"
                           v-model="documents"></FileInput>
                <v-divider v-if="(activeIncidentPhotos && activeIncidentPhotos.length) || (activeIncidentDocuments && activeIncidentDocuments.length)" class="my-3" />
                <div class="photosWrapper" v-if="!photosAreLoading">
                    <div v-for="(image) in activeIncidentPhotos" :key="image.id" class="photo">
                        <PreviewImage :src="image.content" class="image"></PreviewImage>
                        <v-icon size="18" @click="removePhoto(image.id)" class="deleteIcon clickable">{{ ICONS.DELETE }}</v-icon>
                    </div>
                </div>
                <div class="documentsWrapper" v-if="!documentsAreLoading">
                    <div v-for="(document) in activeIncidentDocuments" :key="document.id" class="document">
                        <a class="font-weight-bold mr-1" :href="document.content" download>{{ document.filename }}</a>
                        <v-icon size="18" @click="removeDocument(document.id)" class="deleteIcon clickable">{{ ICONS.DELETE }}</v-icon>
                    </div>
                </div>
                <CircularLoader v-else />
            </v-col>
        </v-row>
    </ValidationObserver>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import { PARSE_DATE, PARSE_TIME } from '@/helpers/dates';
import { ICONS } from '@/constants/icons';
import Autocomplete from '@/components/widgets/forms/Autocomplete';
import TextareaField from '@/components/widgets/forms/TextareaField';
import TextField from '@/components/widgets/forms/TextField';
import FileInput from '@/components/widgets/forms/FileInput';
import DatePicker from '@/components/widgets/forms/DatePicker';
import TimePicker from '@/components/widgets/forms/TimePicker';
import debouncedResponsibleEmployee from '@/mixins/debounced/debouncedResponsibleEmployee';
import debouncedCustomers from '@/mixins/debounced/debouncedCustomers';
import debouncedSites from '@/mixins/debounced/debouncedSites';
import debouncedLocations from '@/mixins/debounced/debouncedLocations';
import debouncedIncidentTypes from '@/mixins/debounced/debouncedIncidentTypes';
import debouncedIncidentStatuses from '@/mixins/debounced/debouncedIncidentStatuses';
import incidentFormFields from '@/mixins/incidentFormFields';
import PreviewImage from '@/components/widgets/PreviewImage.vue';
import { ActionStatus } from '@/constants/status';
import CircularLoader from '@/components/widgets/CircularLoader.vue';

export default {
    name: 'ManageIncidentForm',
    components: { CircularLoader, PreviewImage, ValidationObserver, Autocomplete, TextareaField, FileInput, DatePicker, TimePicker, TextField },
    mixins: [
        debouncedResponsibleEmployee,
        debouncedCustomers,
        debouncedSites,
        debouncedLocations,
        incidentFormFields,
        debouncedIncidentTypes,
        debouncedIncidentStatuses
    ],
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        },
        isFromShortcut: {
            type: Boolean,
            'default': false
        }
    },
    data: () => ({
        acceptedFiles: 'image/png,image/gif,image/jpeg',
        ICONS,
        types: [],
        employees: [],
        photosAreLoading: false,
        documentsAreLoading: false
    }),
    computed: {
        ...mapState('manageIncident', [
            'activeIncidentPhotos',
            'activeIncidentPhotosStatus',
            'activeIncidentDocuments',
            'activeIncidentDocumentsStatus'
        ]),
        ...mapFields('manageIncident', [
            'incidentData.customerId',
            'incidentData.siteId',
            'incidentData.locationId',
            'incidentData.type',
            'incidentData.typeName',
            'incidentData.status',
            'incidentData.personAssigned',
            'incidentData.description',
            'incidentData.files',
            'incidentData.documents',
            'incidentData.end_incident_date',
            'incidentData.customerName',
            'incidentData.siteName',
            'incidentData.locationName',
            'incidentData.startDate',
            'incidentData.startTime',
            'incidentData.endDate',
            'incidentData.endTime',
            'incidentFormVisibility',
            'editedIncidentId',
            'shortcutTypeId',
            'activeIncident'
        ]),
        ...mapGetters('customers', [
            'listingCustomers'
        ]),
        ...mapState('customers', [
            'totalCustomersAmount'
        ]),
        ...mapGetters('sites', [
            'sites'
        ]),
        ...mapState('sites', [
            'totalSitesAmount'
        ]),
        ...mapGetters('locations', [
            'locations',
            'inspectors'
        ]),
        ...mapState('locations', [
            'totalLocationsAmount'
        ]),
        ...mapGetters({
            locationsRelatedTypes: 'locations/locationsTypes',
            locationsRelatedEmployees: 'locations/locationsInspectors',
            inspectors: 'locations/inspectors'
        }),
        ...mapGetters('incidents', [
            'filterableStatusesList'
        ]),
        ...mapState('incidents', [
            'totalIncidentTypesAmount'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        requiredEndDate () {
            if (this.filterableStatusesList !== undefined && this.filterableStatusesList.length > 0) {
                return this.filterableStatusesList.find(e => e.value === this.status)?.text === 'closed';
            } else return false;
        },
        ResponsibleEmployeeLabel () {
            if (this.personAssigned && this.employees?.length > 0 && !this.employees.some(el => el.value === this.personAssigned)) {
                return this.activeIncident.responsible_employee_name + '(' + this.$t('labels.removed') + ')' || '';
            } else return '';
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        },
        showSiteSuccessMessage () {
            return this.customerId ? '' : this.$t('messages.choose_customer');
        },
        showLocationSuccessMessage () {
            return this.siteId ? '' : this.$t('messages.choose_site');
        },
        showStatusSuccessMessage () {
            return this.type ? '' : this.$t('messages.choose_incident_type');
        },
        statusParams () {
            return {
                'filter[incident_kind_id]': this.type
            };
        }
    },
    watch: {
        customerId: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getSites(null, null, newVal);
                }
                if (!newVal && !this.isEditable) {
                    this.siteId = null;
                    this.locationId = null;
                }
            }
        },
        siteId: {
            handler (newVal, oldVal) {
                if (this.isEditable) {
                    this.locationId = this.activeIncident.location_id;
                } else if (newVal !== oldVal && newVal) {
                    this.getLocations(null, null, newVal);
                } else if (!newVal && !this.isEditable) {
                    this.locationId = null;
                }
            }
        },
        locationId: {
            handler (newVal, oldVal) {
                if (newVal !== oldVal && newVal) {
                    this.getDataForLocationId();
                }
            }
        },
        incidentFormVisibility: {
            handler (val) {
                if (val && !this.isEditable) {
                    this.getCustomers(null, null, true);

                    const dateNow = new Date();
                    this.startDate = PARSE_DATE(dateNow);
                    this.startTime = PARSE_TIME(dateNow);
                } else if (val) {
                    this.customersPaginationPage = 1;
                }
            },
            immediate: true
        },
        type: {
            handler (val, oldVal) {
                if (this.isFromShortcut && val && !oldVal) {
                    this.handleAddingFromShortcut();
                }

                if (val && val !== oldVal) {
                    this.getIncidentQuestions(val);
                    this.getStatuses();
                } else if (!val) {
                    this.status = null;
                }
            }
        },
        types: {
            handler (val) {
                if (val && val.length > 0 && !val.find(e => e.value === this.type)) {
                    this.status = null;
                    this.type = null;
                    this.typeName = null;
                }
            },
            immediate: true
        },
        status: {
            handler (val) {
                if (!val || this.filterableStatusesList.find(e => e.value === this.status)?.text !== 'closed') {
                    this.endDate = null;
                    this.endTime = null;
                } else if (val && !this.endDate && !this.endTime) {
                    const dateNow = new Date();
                    this.endDate = PARSE_DATE(dateNow);
                    this.endTime = PARSE_TIME(dateNow);
                }
            },
            immediate: true
        },
        activeIncidentPhotosStatus: {
            handler (val) {
                this.photosAreLoading = val === ActionStatus.PENDING;
            },
            immediate: true
        },
        activeIncidentDocumentsStatus: {
            handler (val) {
                this.documentsAreLoading = val === ActionStatus.PENDING;
            },
            immediate: true
        },
        filterableStatusesList: {
            handler (val) {
                if (val && val.length > 0 && !this.status) {
                    this.status = val.find(e => e.isDefault === 1)?.value;
                }
            }
        },
        totalCustomersAmount: {
            handler (val) {
                if (val === 1) {
                    this.customerId = this.listingCustomers[0]?.value;
                }
            }
        },
        totalSitesAmount: {
            handler (val) {
                if (val === 1) {
                    this.siteId = this.sites[0]?.value;
                }
            }
        },
        totalLocationsAmount: {
            handler (val) {
                if (val === 1) {
                    this.locationId = this.locations[0]?.value;
                }
            }
        }
    },
    methods: {
        getStatuses () {
            return this.getIncidentStatuses(this.statusParams);
        },
        getIncidentQuestions (val) {
            this.$emit('getCurrentIncidentQuestions', val);
        },
        getDataForLocationId () {
            if (this.isEditable) {
                this.getLocations(null, this.locationId, null).then(() => {
                    this.types = this.locationsRelatedTypes(this.locationId);
                    this.employees = this.locationsRelatedEmployees(this.locationId);
                });
            } else {
                this.types = this.locationId ? this.locationsRelatedTypes(this.locationId) : [];
                this.employees = this.locationId ? this.locationsRelatedEmployees(this.locationId) : [];
            }
        },
        clearEndTime (event) {
            if (event === null) this.endTime = null;
        },
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            this.validateAssignedPerson();
            return this.$refs.obs.validate();
        },
        validateAssignedPerson () {
            if (this.personAssigned && this.employees?.length > 0 && !this.employees.some(el => el.value === this.personAssigned)) {
                this.personAssigned = null;
            }
        },
        clearFiles () {
            this.$refs.files.clearFiles();
            this.$refs.documents.clearFiles();
        },
        removePhoto (id) {
            this.$store.dispatch('manageIncident/deleteIncidentPhoto', id);
        },
        removeDocument (id) {
            this.$store.dispatch('manageIncident/deleteIncidentDocument', id);
        },
        handleAddingFromShortcut () {
            if (this.type && this.typeName) {
                this.getIncidentQuestions(this.type);
                this.types = [{ value: this.type, text: this.typeName }];
            }
        }
    },
    created () {
        this.getStatuses();

        if (this.isEditable) {
            this.getDataForLocationId();
        } else if (this.isFromShortcut) {
            this.handleAddingFromShortcut();
        }
    }
};
</script>

<style scoped lang="scss">
@import "@/assets/css/_variables.scss";

::v-deep {
    & .v-input__icon--clear {
        & button {
            &::before {
                font-size: 16px;
            }
        }
    }

    & .v-input--is-disabled {
        & .v-messages__message {
            font-size: $xxs;
        }
    }
}

.photosWrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 16px 0;
    gap: 10px;
    .photo {
        display: flex;
        line-height: 0;
        position: relative;
    }
    .deleteIcon {
        background: white;
        position: absolute;
        top: 0;
        right: 0;
    }
}
</style>
